<template>
    <div class="blog-wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">Want to know whats happening?</h1>
                        <h4>We want to keep you updated, and keep you posted on whats happening in the world of medical.</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="section">
                <div class="container">                    
                    <!-- Data -->
                    <fade-transition>
                        <div class="row">
                            <div class="col-md-12 ml-auto mr-auto" v-if="uiModel.articles.length === 0 && !uiModel.isLoading">
                                No articles found <i class="far fa-frown"></i>
                            </div>

                            <div class="articles-container d-flex flex-wrap" v-if="uiModel.articles.length > 0">
                                <article class="blog-card-wrapper p-0 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex" v-for="article in uiModel.articles" :key="article.id" @click="redirectToArticle(article)">
                                    <div class="blog-card">
                                        <figure class="p-0 m-0">
                                            <div class="posted-on text-center d-flex">
                                                <div class="justify-content-center">
                                                    <div class="day">{{ article.datePublished | moment("DD") }}</div>
                                                    <div class="month">{{ article.datePublished | moment("MMM") }}</div>
                                                </div>
                                                <div class="year justify-self-center align-self-center ml-3">{{ article.datePublished | moment("YYYY") }}</div>
                                            </div>

                                            <img slot="image" class="card-img-top" :src="uiModel.imageBaseUrl + article.header" alt="Article Header" v-show="article.loaded" @load="article.updateLoadingStatus()" />
                                            <img slot="image" class="card-img-top loader-image" src="/img/shared/img_loader.gif" width="50" alt="Article Header" v-show="!article.loaded" />
                                        </figure>
                                        <div class="p-4">
                                            <div class="card-title mb-4 fs-3">{{ article.subject }}</div>
                                            <p class="card-text">{{ article.teaser }}</p>
                                        </div>
                                    </div>
                                </article>
                            </div>

                            <!-- Loading indicator -->
                            <div class="col-md-12 d-flex justify-content-center" v-if="uiModel.isLoading">
                                <loading-indicator></loading-indicator>
                                <div>&nbsp;Loading articles...</div>
                            </div>

                            <div class="col-md-12 d-flex justify-content-center" v-if="uiModel.articles.length < uiModel.total">
                                <n-button type="primary" @click="loadMoreBlogArticles" :disabled="uiModel.isLoading">Load More</n-button>
                            </div>
                        </div>
                    </fade-transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
import { Button } from '@/components';

import NewsService from '@/services/newsService';

export default {
    name: 'news',
    bodyClass: 'news-page',
    components: {
        FadeTransition,
        [Button.name]: Button
    },
    data() {
        return {
            uiModel: {
                imageBaseUrl: `${process.env.VUE_APP_GATEWAYENDPOINT}/storage/storage/download/blob/news/`,
                imageLoader: `/img/shared/img_loader.gif`,
                isLoading: true,
                total: 0,
                articles: []
            },
            searchModel: {
                page: 1,
                pageSize: 9,

                toJsonModel() {
                    var self = this;
                    return {
                        "count": self.pageSize,
                        "page": self.page
                    };
                }
            }
        };
    },
    methods: {
        getBlogArticles() {
            var self = this;
            var newsService = new NewsService();

            self.uiModel.isLoading = true;
            newsService.getAllNewsArticles(self.searchModel.toJsonModel(), self.handleGetAllNewsArticlesResponse);
        },
        loadMoreBlogArticles() {
            var self = this;
            self.searchModel.page += 1;
            self.getBlogArticles();
        },
        redirectToArticle(article) {
            var self = this;
            self.$router.push({name:"article", params: { articleid: article.id }}); 
        },
        handleGetAllNewsArticlesResponse(response) {
            var self = this;
            self.uiModel.isLoading = false;

            if(response.status === 200) {
                self.uiModel.total = response.data.result.total;
                self.uiModel.articles = self.uiModel.articles.concat(self.toArticleJsonModel(response.data.result.articles));
            }
        },
        toArticleJsonModel(serverArticles) {
            let articles = [];
            for(let i = 0; i < serverArticles.length; i++) {
                articles.push({
                    id: serverArticles[i].id,
                    header: serverArticles[i].articleDetail.header.name,
                    subject: serverArticles[i].articleDetail.subject,
                    teaser: serverArticles[i].articleDetail.teaser,
                    datePublished: serverArticles[i].articleStatus.datePublished,
                    loaded: false,

                    updateLoadingStatus: function() {
                        this.loaded = true
                    }
                });
            }

            return articles;
        }
    },
    mounted() {
        var self = this;
        self.getBlogArticles();
    },

    metaInfo() {
        return {
            title: "DrRemo | Blog",
            meta: [
                {
                    name: "description",
                    content: "Get up to speed on the latest news in the wellness and telehealth industry."
                },
                {
                    name: "robots",
                    content: "index, follow"
                }
            ],
            link: [
                {
                rel: "canonical",
                href: "https://dr-remo.co.za/blog"
                }
            ]
        }
    }
};
</script>
