import axios from 'axios'

export default class NewsService {
    getAllNewsArticles(newsModel, handleResponse) {
        var endPoint = `${process.env.VUE_APP_GATEWAYENDPOINT}/news/news/get/all`;
        axios
            .post(endPoint, newsModel)
            .then(response => handleResponse(response))
            .catch(error => {
                handleResponse(error);
            });
    }

    getArticleDetails(articleModel, handleResponse) {
        var endPoint = `${process.env.VUE_APP_GATEWAYENDPOINT}/news/news/get/article`;
        axios
            .post(endPoint, articleModel)
            .then(response => handleResponse(response))
            .catch(error => {
                handleResponse(error);
            });
    }
}